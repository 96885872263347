import React, { useEffect, useRef, useState } from "react";
import "../scss/BlogPage4.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import FK from "./FK.png"; 
import email from "./email_black.png";
import leenanair from "./leena_nair.png";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import yemihead from "./yemi_head.png";
import mindblog from "./mind blog.png";

export default function BlogPage4() {
  const data = useStaticQuery(graphql`
  query MyQueryBlogPage4 {
    allContentfulHeroImage (filter:{id:{eq:"a333d9bd-dd97-507d-918f-0bf86546e42f"}}){
      edges {
        node {
          blogCategory
          blogTitle
          date
          readTime
          author {
            firstName
          }
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
          heroImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
}
  `);


  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const breakpoint = 2000; // Adjust this value to determine when the icons should disappear

      if (scrollPosition > breakpoint) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };
  

  const [isSocialMediaBlogVisible, setIsSocialMediaBlogVisible] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 6500; // Adjust this value to determine when to hide socialMediaBlog

      if (scrollPosition > scrollThreshold) {
        setIsSocialMediaBlogVisible(false);
      } else {
        setIsSocialMediaBlogVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  return (
    <div className={`DTM`}>
    <div className="contentFeatureBlogDTM">
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="A Complete Mindfulness Guide for Luxury Leaders" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/5aqDExVt6DKOtc9Qt8SvfS/e8655a41a19587fad21895e8fd7f0ed6/mind_blog.png" />
        <meta property="og:description" content="They say it’s all in your mind. That all our challenges and everything standing in the way of what we want is down to how we use our minds." />
        <meta property="og:url" content="https://thefreshkid.com/mindfulness-luxuryleadership/" />
      </Helmet>


      <div className="SocialMediaBlog3M" data-aos="fade-up">
      {isSocialMediaBlogVisible && (
          <>
            <div
              className="Share3M"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/mindfulness-luxuryleadership">
              <img
                className="SocialIconBlog3M"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/mindfulness-luxuryleadership/" target="_blank">
            <img
                className="SocialIconBlog3M"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: A Complete Mindfulness Guide for Luxury Leaders&body=Check out this article: A Complete Mindfulness Guide for Luxury Leaders www.thefreshkid.com/mindfulness-luxuryleadership/" target="_blank">
              <img
                className="SocialIconBlog13M"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div>

      <div className="image-and-text-containerM">
        <div className="image-containerM">
            <img
            className={"imgLDT small-imageM"}
            src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}
            alt="how to practice mindfulness for luxury leaders"
            />
        </div>
        <div className="text-containerM">
        </div>
      </div>

      <div className="image-and-text-containerMediaQuery">
        <div className="text-containerMediaQuery">
            <p className={"categoryBlogDTM"}>
            {data.allContentfulHeroImage.edges[0].node.blogCategory}
            </p>
            <p className={"titleBlogDTM"}>
            {data.allContentfulHeroImage.edges[0].node.blogTitle}
            </p>
            <p className={"lineDT2M"}>______________</p>

            
            <div className="digitalColumnM">
              <div className={"digitalImageM"}>
                <img
                  className={"bigImage"}
                  alt={""}
                  key={""}
                  src={
                    yemihead
                  }
                />
              </div>
              <div className={"digitalTextM"}>
                <p className={"authorBlogDTM"}>
                {data.allContentfulHeroImage.edges[0].node.author.firstName}
                </p>
                <p className={"dateBlogDTM"}>
                {data.allContentfulHeroImage.edges[0].node.date}
                </p>
                <p className={"readTimeBlogDTM"}>
                {data.allContentfulHeroImage.edges[0].node.readTime}
                </p>
                </div>
            </div>
        </div>
        <div className="image-containerMediaQuery">
            <img
            className={"imgLDTMediaQuery small-imageMediaQuery"}
            src={mindblog}
            alt="Mindfulness Luxury Leadership"
            />
        </div>
      </div>

        

        {/* {isContentsVisible && (
        <div>
          <div className="contentsListb3">
            <p className="contentb3">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}

      <div id="1" className="nav1">1</div>
      <div id="2" className="nav2">2</div>
      <div id="3" className="nav3">3</div>
      <div id="4" className="nav4">4</div>
      <div id="5" className="nav5">5</div> */}




      {/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_sticky_social_bar */}


      </div>
      {/* <div style={{ marginTop: "300vw" }}>
        <NewsletterBar />
      </div>
      
      <Footer /> */}
    </div>
  );
}



