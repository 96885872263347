import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogPage4 from "../components/BlogPage/BlogPage4"
import BlogPage4All from "../components/BlogPage/BlogPage4All"
import BlogContents from '../components/BlogPage/BlogContents'
import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    
    <LayoutBlog>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="A Complete Mindfulness Guide for Luxury Leaders" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/5aqDExVt6DKOtc9Qt8SvfS/e8655a41a19587fad21895e8fd7f0ed6/mind_blog.png" />
        <meta property="og:description" content="They say it’s all in your mind. That all our challenges and everything standing in the way of what we want is down to how we use our minds." />
        <meta property="og:url" content="https://thefreshkid.com/mindfulness-luxuryleadership/" />
      </Helmet>
      <BlogPage4/>
      <BlogPage4All/>
      {/* <BlogPage3All/> */}
      {/* <BlogPage3Text1/>
      <BlogPage3Pic1/>
      <BlogPage3Text2/>
      <BlogPage3Pic2/>
      <BlogPage3Quote/>
      <BlogPage3Text3/>
      <BlogPage3Pic3/>
      <BlogPage3Text4/>
      <BlogPage3Pic4/>
      <BlogPage3Text5/>
      <BlogPage3Text6/> */}
      {/* <BlogContents /> */}
    </LayoutBlog>
  )
}

export default blog





