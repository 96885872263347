import React, { useState, useEffect } from "react";
import "../scss/BlogPage4All.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import bof from './bof.png';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';
import book1 from './book1111.png';
import book2 from './book2222.png';
import book3 from './book3333.png';
import book4 from './book4444.png';
import book5 from './book5555.png';
import leftArrow from './left.png';
import rightArrow from './right.png';
import { DiscussionEmbed } from "disqus-react";
import quote from './quote.png';


const BlogPage4All = () => {

    const data = useStaticQuery(graphql`
    query MyQueryMindset {
        text1: allContentfulTextBlock(filter: { id: { eq: "f4d9d6f9-edda-56a8-a546-efb25fc86038" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text2: allContentfulTextBlock(filter: { id: { eq: "96f1cca3-6e66-5d2c-85a2-4af822bd73ae" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text3: allContentfulTextBlock(filter: { id: { eq: "376dc809-eedc-5181-a19e-91532d37139e" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text4: allContentfulTextBlock(filter: { id: { eq: "7b193d21-f536-56cf-94e1-abd3dfe2c282" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text5: allContentfulTextBlock(filter: { id: { eq: "52860d1b-078f-5a6a-befb-8606af4f9e0f" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        pic1: allContentfulImageBlock(filter: { name: { eq: "Blog 4 Image 1" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic2: allContentfulImageBlock(filter: { name: { eq: "Blog 4 Image 2" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic3: allContentfulImageBlock(filter: { name: { eq: "Blog 4 Image 3" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic4: allContentfulImageBlock(filter: { name: { eq: "Blog 4 Image 5" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic5: allContentfulImageBlock(filter: { name: { eq: "Blog 4 Image 6" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }


    }
  `);


  const t1 = data.text1.edges[0].node;
  const t2 = data.text2.edges[0].node;
  const t3 = data.text3.edges[0].node;
  const t4 = data.text4.edges[0].node;
  const t5 = data.text5.edges[0].node;
  const p1 = data.pic1.edges[0].node;
  const p2 = data.pic2.edges[0].node;
  const p3 = data.pic3.edges[0].node;
  const p4 = data.pic4.edges[0].node;
  const p5 = data.pic5.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    const [activeSection, setActiveSection] = useState("");

    const sectionIds = [
      "It's all in your mind",
      "Nurture and Protect",
      "Exercise",
      "You are what you read",
      "Sleep and rest Well",
    ];
  
    useEffect(() => {
      const handleScroll = () => {
        const sectionOffsets = sectionIds.map((sectionId) => {
          const sectionElement = document.getElementById(sectionId);
          return {
            id: sectionId,
            offsetTop: sectionElement.offsetTop,
            offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
          };
        });
    
        const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
        for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
          if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
            setActiveSection(id);
            break;
          }
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);







    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 500; // Adjust this value to determine when to show the contents
      const hideThreshold = 10000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleArrowClick = () => {
      // Scroll down 300 pixels when the arrow is clicked
      window.scrollBy({
        top: 400,
        behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
      });
    };

    const images = [
      book1, book2, book3, book4, book5,
    ];

    const texts = [
      {
        title: {
          text: 'Radical Candour - Silicon Valley Technology Leadership Handbook',
          className: 'book-title book1-title',
        },
        description: {
          text: 'If you are responsible for the performance of a team especially technology teams this book is invaluable.',
          className: 'book-description book1-description',
        },
      },
      {
        title: {
          text: 'Rich Dad Poor Dad - Investment Mindset Advice',
          className: 'book-title book2-title',
        },
        description: {
          text: 'A good place to start for those starting on their investment journey.',
          className: 'book-description book2-description',
        },
      },
      {
        title: {
          text: 'The Art of Being and Becoming - Personal Development',
          className: 'book-title book3-title',
        },
        description: {
          text: 'A belief system of how to conduct yourself at work and in life.',
          className: 'book-description book3-description',
        },
      },
      {
        title: {
          text: 'The Aficionados - Luxury Travel and Design',
          className: 'book-title book4-title',
        },
        description: {
          text: 'The world’s most tasteful hotels, designers and tastemakers curated',
          className: 'book-description book4-description',
        },
      },
      {
        title: {
          text: 'Monocle - Design, Travel and Taste',
          className: 'book-title book5-title',
        },
        description: {
          text: 'For all things related to good taste',
          className: 'book-description book5-description',
        },
      },
    ];
    
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
  
    const prevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const disqusConfig = {
      shortname: "tfk-1",
      config: {
      },
      language: "en",
    };

 



  return (
    <>
      <Helmet>
        <title>Mindfulness - Luxury Leadership</title>
        <meta name="description" content="A Complete Mindfulness Guide for Luxury Leaders - Discover how to improve your performance as a luxury leader" />
        <meta name="keywords" content="How to practice mindfulness for luxury leadership, Mindfulness luxury leadership" />
        <h1>A Complete Mindfulness Guide for Luxury Leaders</h1>
      </Helmet>
    <div className={`all1`}>
    <div className={`check1`}>



    {isContentsVisible && (
        <div>
          <div className="contentsListads">
            <p className="contentads">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                // href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )}



      <div>
        <Helmet>
          <h2>It's all in Your Mind</h2>
        </Helmet>
        <div id="It's all in your mind" className="contentFeatureBlogPage3Text11M">
          <div className="textContainerBlogPage3Text11M">
            <p className="titleBlogPage3Text11M">{t1.title}</p>
            {t1.bodyText && (
              <div className="bodyBlogPage3Text11M">
                {documentToReactComponents(JSON.parse(t1.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog11'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <div id="Nurture and Protect" className="contentFeatureBlogPagePic11M">
          <div className={"imageContainerBlogPagePic11M"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="How to practice mindfulness for luxury leadership"
              key={""}
              src={p1.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <Helmet>
          <h3>Nurture and Protect your Mind</h3>
        </Helmet>
        <div className="contentFeatureBlogPage3Text22M">
          <div className="textContainerBlogPage3Text22M">
            <p className="titleBlogPage3Text22M">{t2.title}</p>
            {t2.bodyText && (
              <div className="bodyBlogPage3Text22M">
                {documentToReactComponents(JSON.parse(t2.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog22'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>

      <div>
        <div id="Exercise" className="contentFeatureBlogPagePic22M">
          <div className={"imageContainerBlogPagePic22M"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="Mindfulness luxury leadership"

              key={""}
              src={p2.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
      <Helmet>
          <h3>Exercise is Fuel for the Mind</h3>
        </Helmet>
        <div className="contentFeatureBlogPage3Text33M">
          <div className="textContainerBlogPage3Text33M">
            <p className="titleBlogPage3Text33M">{t3.title}</p>
            {t3.bodyText && (
              <div className="bodyBlogPage3Text33M">
                {documentToReactComponents(JSON.parse(t3.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlog33'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>



      <div>
        <div id="You are what you read" className="contentFeatureBlogPagePic33M">
          <div className={"imageContainerBlogPagePic33M"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="The Fresh Kid"
              key={""}
              src={p3.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
      <Helmet>
          <h3>You are what you Read</h3>
        </Helmet>
        <div className="contentFeatureBlogPage3Text44M">
          <div className="textContainerBlogPage3Text44M">
            {t4.bodyText && (
              <div className="bodyBlogPage3Text44M">
                {documentToReactComponents(JSON.parse(t4.bodyText.raw))}
              </div>
            )}
            <p className="titleBlogPage3Text44M">{t4.title}</p>
          </div>
          <div className={'arrowContainerBlog44'}>
              <img
                className={'arrow'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>





      <div>
      <div className={`text-containerScrollTitle ${texts[currentImageIndex].title.className}`}>
        <p className="title-textM">{texts[currentImageIndex].title.text}</p>
        <p className={`description-textM ${texts[currentImageIndex].description.className}`}>
          {texts[currentImageIndex].description.text}
        </p>
      </div>

        <div className="scrollM">
        <div className="scroll-containerM">
          <div className="image-wrapperM">
            <img
              src={images[currentImageIndex]}
              width="780"
              alt={`Book ${currentImageIndex + 1}`}
            />
            {/* <div className="text-containerM">
              <p className="description-textM">{texts[currentImageIndex][1]}</p>
            </div> */}
            <div className="button-containerM">
              <div className="button-leftM">
                <img src={leftArrow} alt="Left" onClick={prevImage} />
              </div>
              <div className="button-rightM">
                <img src={rightArrow} alt="Right" onClick={nextImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


      <div>
      <div className={'arrowContainerBlog77'}>
            <img
              className={'arrow77'}
              src={arrow}
              alt="arrow"
              onClick={handleArrowClick} // Attach the click handler here
            />
        </div>
      <div>
        <div className="last-text-for-scroll">
                <p className="sleep"><strong>Sleep and Rest Well</strong></p>
        </div>
        <div id="Sleep and rest Well" className="contentFeatureBlogPagePic44M">
          <div className={"imageContainerBlogPagePic44M"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt=""
              key={""}
              src={quote}
            />
          </div>
        </div>
      </div>
      </div>

      <div>
        <Helmet>
          <h3>Sleep and Rest Well</h3>
        </Helmet>
        <div className="contentFeatureBlogPage3Text55M">
          <div className="textContainerBlogPage3Text55M">
            {t5.bodyText && (
              <div className="bodyBlogPage3Text55M">
                {documentToReactComponents(JSON.parse(t5.bodyText.raw))}
              </div>
            )}
            <p className="titleBlogPage3Text55M">{t5.title}</p>
          </div>
        </div>
      </div>



      <div>
        <div className="contentFeatureBlogPagePic55M">
          <div className={"imageContainerBlogPagePic55M"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="The Fresh Kid"
              key={""}
              src={p5.image.image.file.url}
            />
          </div>
        </div>
      </div>

      <div>
      <div className="comment-box-title4">Join the Conversation</div>
      <div className="comment-box-container4">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </div>


      
    </div>
    {/* <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div> */}
{/*       
      <Footer /> */}
    </div>
    </>
  );
}

export default BlogPage4All;



